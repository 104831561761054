'use client';

import styled from 'styled-components';

import Section from '@/components/ui/Section';
import { Heading, Paragraph } from '@swordhealth/ui-corporate';
import { TertiaryButton } from '@/components/ui/Button';
import { Container } from '@/components/ui/flexbox';
import { Footer } from '@/app/_components/platform';

import ErrorSVG from '@/public/icons/page-error.svg';

const StyledSection = styled(Section)`
  display: flex;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 440px);
`;

const Text = styled(Paragraph)`
  color: ${(props) => props.theme.colors.grey[800]};
  text-align: center;
  max-width: 620px;
`;

const MainButton = styled(TertiaryButton)`
  padding-left: 32px;
  padding-right: 32px;
  font-size: 16px;
  line-height: 24px;
  height: 48px;

  .loading-spinner {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  gap: 24px;
  justify-items: center;
  margin: 0 auto;
`;

const ImageWrapper = styled(ErrorSVG)`
  height: 44vh;
  max-height: 389px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 22vh;
  }
`;
const NotFoundContent = () => {
  return (
    <>
      <StyledSection spacerTop>
        <Container>
          <Content>
            <ImageWrapper aria-hidden={true} />
            <Heading variant="3">What the Health?</Heading>
            <Text>{`Oops, you’ve hit a wall. This page is either under construction, or doesn't exist. Apologies for the inconvenience! Head home to learn more about our mission to free 2 billion from pain.`}</Text>
            <MainButton href="/">Return Home</MainButton>
          </Content>
        </Container>
      </StyledSection>
      <Footer />
    </>
  );
};
export default NotFoundContent;
